import {
  Box,
  Center,
  Container,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useMediaQuery,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';

import { WinnerPrize, WinnerPrizeDetail } from '@interfaces/Winners';
import {
  getWinnerPrizesDetailReq,
  getWinnerPrizesReq,
} from '@services/winner_prize';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';

export default function WinnerPrizes() {
  const SHOW_PRIZE_MESSAGE = false;
  const [winners, setWinners] = useState<WinnerPrize[]>();
  const [winnersPrize, setWinnersPrize] = useState<WinnerPrizeDetail[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedWinner, setSelectedWinner] = useState<WinnerPrize | null>(
    null
  );
  const [isLargerThan400] = useMediaQuery('(min-width: 400px)');
  const { onClose } = useDisclosure();
  const { t } = useTranslation('landing-page');

  const handleSelectWinnerPrize = (winner: WinnerPrize) => {
    setSelectedWinner(winner);
    setIsLoading(true);
    setWinnersPrize([]);
    getWinnerPrizesDetailReq({
      idPrize: winner.idPrize,
      type: winner.type,
    }).then((value) => {
      if (value != undefined) {
        setWinnersPrize(value);
      }
      setIsLoading(false);
    });
  };

  const handleOnCloseModal = () => {
    onClose();
    setSelectedWinner(null);
  };

  useEffect(() => {
    if (!SHOW_PRIZE_MESSAGE) {
      getWinnerPrizesReq().then((value) => {
        if (value != undefined) {
          setWinners(value.sort((a, b) => b.idPrize - a.idPrize));
        }
      });
    }
  }, []);

  const renderExibitionInfos = () => {
    if (isLargerThan400) {
      return (
        <TableContainer>
          <Table variant="striped">
            <TableCaption>
              <Text fontFamily={'Raleway'} fontWeight={400} color={'#1D1D1D'}>
                {t('winner-modal-txt-description', {
                  count: winnersPrize?.length,
                })}
              </Text>
            </TableCaption>
            <Thead>
              <Tr>
                <Th>
                  <Text
                    fontFamily={'mainfont'}
                    fontWeight={700}
                    color={'#1D1D1D'}
                  >
                    {t('winner-table-txt-login')}
                  </Text>
                </Th>
                <Th>
                  <Text
                    fontFamily={'mainfont'}
                    fontWeight={4700}
                    color={'#1D1D1D'}
                  >
                    {t('winner-table-txt-status')}
                  </Text>
                </Th>
                <Th>
                  <Text
                    fontFamily={'mainfont'}
                    fontWeight={700}
                    color={'#1D1D1D'}
                  >
                    {t('winner-table-txt-date')}
                  </Text>{' '}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {winnersPrize
                ?.sort(function (a, b) {
                  const now = new Date();
                  const aDate =
                    a.dt_delivery != null ? new Date(a.dt_delivery) : now;
                  const bDate =
                    b.dt_delivery != null ? new Date(b.dt_delivery) : now;
                  return aDate.getTime() - bDate.getTime();
                })
                .map((e) => {
                  let date = '';
                  if (e.dt_delivery != null && e.dt_delivery != undefined) {
                    date = new Date(e.dt_delivery).toLocaleDateString('pt-BR');
                  }
                  return (
                    <Tr>
                      <Td>
                        <Text
                          fontFamily={'Raleway'}
                          fontWeight={400}
                          color={'#1D1D1D'}
                        >
                          {e.login}
                        </Text>
                      </Td>
                      <Td>
                        <Text
                          fontFamily={'Raleway'}
                          fontWeight={400}
                          color={'#1D1D1D'}
                        >
                          {e.status}
                        </Text>
                      </Td>
                      <Td>
                        <Text
                          fontFamily={'Raleway'}
                          fontWeight={400}
                          color={'#1D1D1D'}
                        >
                          {date}
                        </Text>
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </TableContainer>
      );
    }
    return (
      <VStack>
        {winnersPrize
          ?.sort(function (a, b) {
            const now = new Date();
            const aDate = a.dt_delivery != null ? new Date(a.dt_delivery) : now;
            const bDate = b.dt_delivery != null ? new Date(b.dt_delivery) : now;
            return aDate.getTime() - bDate.getTime();
          })
          .map((e, index) => {
            let date = '';
            if (e.dt_delivery != null && e.dt_delivery != undefined) {
              date = new Date(e.dt_delivery).toLocaleDateString('pt-BR');
            }
            return (
              <VStack alignItems="start" w="full" key={`winner_${index}`}>
                <HStack>
                  <Text fontWeight="bold">{t('winner-table-txt-login')}: </Text>{' '}
                  <Text>{e.login}</Text>
                </HStack>
                <HStack>
                  <Text fontWeight="bold">
                    {t('winner-table-txt-status')}:{' '}
                  </Text>{' '}
                  <Text>{e.status}</Text>
                </HStack>
                <HStack>
                  <Text fontWeight="bold">{t('winner-table-txt-date')}: </Text>{' '}
                  <Text>{date}</Text>
                </HStack>
                <Divider orientation="horizontal" size="2xl" />
              </VStack>
            );
          })}
      </VStack>
    );
  };

  if (SHOW_PRIZE_MESSAGE) {
    return (
      <Box background="linear-gradient(180deg, #FFFFFF 48.75%, rgba(255, 255, 255, 0) 153.44%);">
        <Flex w="full" h="full">
          <Container maxW={{ base: 'full', md: '9xl', lg: '8xl' }}>
            <VStack spacing={5}>
              <Heading
                fontSize={{ base: '24px', md: '32px', lg: '44px' }}
                p={[5, 5, 5, 5]}
                textAlign="center"
                fontFamily={'mainfont'}
                fontWeight={700}
                color={'#1D1D1D'}
              >
                {t('banner-winner-title')}
              </Heading>

              <SimpleGrid columns={2} spacing={[5, 5, 5, 80]}>
                <Center>
                  <Text
                    fontSize={{ base: '14px', md: '18px', lg: '26px' }}
                    align="center"
                  >
                    {t('winner-banner-txt-message')}
                  </Text>
                </Center>
              </SimpleGrid>
            </VStack>
          </Container>
        </Flex>
      </Box>
    );
  }

  return (
    <Box bgColor={'lbBannerWinnerColr'}>
      <Modal
        size="full"
        isOpen={selectedWinner != null}
        onClose={handleOnCloseModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center">
            <Text fontFamily={'mainfont'} fontWeight={700} color={'#1D1D1D'}>
              {selectedWinner?.title}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isLoading ? (
              <VStack>
                <Spinner size="xl" speed="0.65s" />
                <Text fontSize="sm">{t('winner-banner-txt-loading')}</Text>
              </VStack>
            ) : (
              renderExibitionInfos()
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box background="linear-gradient(180deg, #FFFFFF 48.75%, rgba(255, 255, 255, 0) 153.44%);">
        <Flex w="full" h="full" flexDir="column">
          <VStack spacing={5} w="full" h="full">
            <HStack
              alignItems="center"
              justifyContent={'end'}
              w="full"
              h="20vh"
            >
              <VStack
                color="#1F1960"
                position="absolute"
                marginLeft="auto"
                margin-right="auto"
                left={0}
                right={0}
                zIndex={0}
                spacing={1}
              >
                <Heading
                  fontSize={{ base: '24px', md: '32px', lg: '44px' }}
                  px={[5, 5, 5, 5]}
                  textAlign="center"
                >
                  {t('winner-banner-head-title')}
                </Heading>
                <Text
                  fontSize={{ base: '14px', md: '18px', lg: '26px' }}
                  align="center"
                  fontWeight={500}
                >
                  {t('winner-banner-txt-description')}
                </Text>
              </VStack>
              <Image src="imgs/trophy.png" h={['10vh', '20vh']} />
            </HStack>
          </VStack>
          <Center px={['5%', '5%', '5%', '10%', '15%']}>
            <Wrap spacing="30px" justify="center">
              {winners?.map((winner) => {
                return (
                  <WrapItem
                    key={Math.random()}
                    cursor="pointer"
                    _hover={{
                      shadow: 'sm',
                    }}
                    borderRadius="5%"
                  >
                    <Image
                      w={['145', '150', '200', '250', '270', '297']}
                      h={['130', '138', '185', '231', '241', '275']}
                      // my={['1vh']}
                      // mx={['0vw']}
                      onClick={() => handleSelectWinnerPrize(winner)}
                      src={winner.description}
                    />
                  </WrapItem>
                );
              })}
            </Wrap>
          </Center>
        </Flex>
      </Box>
    </Box>
  );
}
